<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          v-if="$store.state.app.windowWidth > 992"
          fluid
          style="width: 240px; max-height: 60px"
          :src="imgLogo"
          alt="Logo"
        />
        <b-img
          v-else
          fluid
          style="width: 150px"
          :src="imgLogoSmall"
          alt="Logo"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Register V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-navbar-nav class="nav align-items-end">
            <locale />
          </b-navbar-nav>

          <b-card-title class="mb-1">
            {{ t("Adventure starts here") }} 🚀
          </b-card-title>
          <b-card-text class="mb-2">
            {{ t("Make your app management easy and fun") }}!
          </b-card-text>

          <!-- form -->
          <validation-observer ref="registerForm" #default="{ invalid }">
            <b-form class="auth-register-form mt-2" @submit.prevent="register">
              <!-- First name -->
              <b-form-group>
                <label for="register-first_name">{{ t("First name") }}</label>
                <validation-provider
                  #default="{ errors }"
                  name="First name"
                  vid="first_name"
                  rules="required"
                >
                  <b-form-input
                    id="register-first_name"
                    v-model="first_name"
                    name="register-first_name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="John"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- username -->
              <b-form-group>
                <label for="register-last_name">{{ t("Last name") }}</label>
                <validation-provider
                  #default="{ errors }"
                  name="last_name"
                  vid="last_name"
                  rules="required"
                >
                  <b-form-input
                    id="register-last_name"
                    v-model="last_name"
                    name="register-last_name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Doe"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group>
                <label for="register-emai">{{ t("Email") }}</label>
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="email"
                    name="register-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group>
                <label for="register-password">{{ t("Password") }}</label>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="new-password"
                      placeholder="Enter new password"
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  name="checkbox-1"
                  v-model="status"
                >
                  {{ t("I agree to") }}
                  <b-link style="color: #e6354a">
                    {{ t("privacy policy & terms") }}
                  </b-link>
                </b-form-checkbox>
              </b-form-group>

              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="invalid || !status || isSignInClicked"
              >
                {{ t("Sign up") }}
              </b-button>
              <div v-if="isSignInClicked" class="spinner-block"><b-spinner variant="primary" /></div>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>{{ t("Already have an account") }}?</span>
            <b-link :to="{ name: 'auth-login' }">
              <span style="color: #e6354a"
                >&nbsp;{{ t("Sign in instead") }}</span
              >
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
  BSpinner,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import {
  loginUser,
  registerUser,
  getHomeRouteForLoggedInUser,
} from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Locale from "@core/layouts/components/app-navbar/components/Locale.vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    Locale,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    const { t } = useI18nUtils();
    return {
      status: false,
      first_name: "",
      last_name: "",
      password: "",
      sideImg: require("@/assets/images/pages/register-v2.svg"),
      imgLogo: require("@/assets/images/logo/3Digital_logo.png"),
      imgLogoSmall: require("@/assets/images/logo/New_Project_2.png"),
      // validation
      required,
      email,
      isSignInClicked: false,
      t,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    async register() {
      this.isSignInClicked = true
      this.$refs.registerForm.validate().then(async (success) => {
        if (success) {
          try  {
            await registerUser(
              this.first_name,
              this.last_name,
              this.email,
              this.password
            )
            loginUser(this.email, this.password)
            .then(() => {
              const userDataAbility = {
                ability: [
                  {
                    action: "manage",
                    subject: "all",
                  },
                ],
              };
              localStorage.setItem(
                "userDataAbility",
                JSON.stringify(userDataAbility)
              );
              this.$ability.update(userDataAbility.ability);
              this.me();
            })
            .catch(error => {
              this.isSignInClicked = false
              this.$refs.registerForm.errors.email.push(err.response.data.email)
              let statusText = error.response.statusText
			        let messageText = error.response.data.detail

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: statusText,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: messageText
                },
              });
            });
          } catch {
            this.isSignInClicked = false
          }
        }
      });
    },
    me() {
      store.dispatch("UserData/GET_USER_DATA").then((res) => {
        this.$router.replace(getHomeRouteForLoggedInUser("admin"));
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Welcome ${res.data.first_name} ${res.data.last_name}`,
            icon: "CoffeeIcon",
            variant: "success",
            text: "You have successfully logged in. Now you can start to explore!",
          },
        });
        store.dispatch("Profile/GET_MY_PROFILE");
      });
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.text-danger {
  &:first-letter {
    text-transform: capitalize !important;
  }
}
.spinner-block {
  position: absolute;
  margin-top: -33px;
  right: 30px;
  height: 0px;
}
</style>
