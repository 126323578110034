var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[(_vm.$store.state.app.windowWidth > 992)?_c('b-img',{staticStyle:{"width":"240px","max-height":"60px"},attrs:{"fluid":"","src":_vm.imgLogo,"alt":"Logo"}}):_c('b-img',{staticStyle:{"width":"150px"},attrs:{"fluid":"","src":_vm.imgLogoSmall,"alt":"Logo"}})],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-navbar-nav',{staticClass:"nav align-items-end"},[_c('locale')],1),_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.t("Adventure starts here"))+" 🚀 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.t("Make your app management easy and fun"))+"! ")]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register($event)}}},[_c('b-form-group',[_c('label',{attrs:{"for":"register-first_name"}},[_vm._v(_vm._s(_vm.t("First name")))]),_c('validation-provider',{attrs:{"name":"First name","vid":"first_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-first_name","name":"register-first_name","state":errors.length > 0 ? false : null,"placeholder":"John"},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('label',{attrs:{"for":"register-last_name"}},[_vm._v(_vm._s(_vm.t("Last name")))]),_c('validation-provider',{attrs:{"name":"last_name","vid":"last_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-last_name","name":"register-last_name","state":errors.length > 0 ? false : null,"placeholder":"Doe"},model:{value:(_vm.last_name),callback:function ($$v) {_vm.last_name=$$v},expression:"last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('label',{attrs:{"for":"register-emai"}},[_vm._v(_vm._s(_vm.t("Email")))]),_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false : null,"placeholder":"john@example.com"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('label',{attrs:{"for":"register-password"}},[_vm._v(_vm._s(_vm.t("Password")))]),_c('validation-provider',{attrs:{"name":"Password","vid":"Password","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"new-password","placeholder":"Enter new password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" "+_vm._s(_vm.t("I agree to"))+" "),_c('b-link',{staticStyle:{"color":"#e6354a"}},[_vm._v(" "+_vm._s(_vm.t("privacy policy & terms"))+" ")])],1)],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":invalid || !_vm.status || _vm.isSignInClicked}},[_vm._v(" "+_vm._s(_vm.t("Sign up"))+" ")]),(_vm.isSignInClicked)?_c('div',{staticClass:"spinner-block"},[_c('b-spinner',{attrs:{"variant":"primary"}})],1):_vm._e()],1)]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v(_vm._s(_vm.t("Already have an account"))+"?")]),_c('b-link',{attrs:{"to":{ name: 'auth-login' }}},[_c('span',{staticStyle:{"color":"#e6354a"}},[_vm._v(" "+_vm._s(_vm.t("Sign in instead")))])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }